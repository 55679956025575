import { SelectField } from "@components/field/selectField";
import { requiredrule } from "@components/form/requiredRule";
import { LoaderComponent } from "@components/loaderComponent/loaderComponent";
import { useGetChallengeTitlesLazyQuery, useGetContentTitlesLazyQuery, useGetEventTitlesLazyQuery } from "@graphql2/types";
import { getText } from "@utils/getText";
import { Alert, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { StepType } from "./singleStepCard";

type TypeIdsFieldProps = {
    stepType: StepType | undefined;
    fieldName: number;
    form: FormInstance;
    stepTypeModifiedManually: boolean;
}

const getSelectMode = (stepType: StepType) => {
    if (stepType === "content") {
        return undefined;
    }

    if (stepType === "typeform") {
        return "tags";
    }

    return "multiple";
};

const MultiLangInfo = () => {
    return (
        <Alert
            showIcon
            description={<FormattedMessage id="flows.typeform.multilang.description" />}
            message={<FormattedMessage id="flows.typeform.multilang.title" />}
            type="warning"
        />
    );
};

export const TypeIdsField = ({ stepType, fieldName, form, stepTypeModifiedManually }: TypeIdsFieldProps) => {
    const [typeIdsData, setTypeIdsData] = React.useState<{ value: string; title: string; }[] | undefined>(undefined);

    React.useEffect(() => {
        switch (stepType) {
            case "content":
                getContents();
                break;
            case "event":
                getEvents();
                break;
            case "challenge":
                getChallenges();
                break;
            default:
                break;
        }
    }, [stepType]);

    React.useEffect(() => {
        if (!stepTypeModifiedManually) {
            return;
        }

        form.setFields([{
            name: ["steps", fieldName, "typeIds"],
            value: []
        }]);
    }, [stepTypeModifiedManually, stepType]);

    const [getEvents, { loading: loadingEvents }] = useGetEventTitlesLazyQuery({
        variables: {
            filter: {
                endsAfter: moment.utc().startOf("day").valueOf()
            }
        },
        onCompleted(data) {
            setTypeIdsData(data.events.map(event => ({ value: event.id, title: getText(event.title) })));
        }
    });

    const [getChallenges, { loading: loadingChallenges }] = useGetChallengeTitlesLazyQuery({
        variables: {
            filter: {
                endsAfter: moment.utc().startOf("day").valueOf()
            }
        },
        onCompleted(data) {
            setTypeIdsData(data.challenges.map(challenge => ({ value: challenge.id, title: getText(challenge.title) })));
        }
    });

    const [getContents, { loading: loadingContents }] = useGetContentTitlesLazyQuery({
        variables: {
            filter: {
                expireAfter: moment.utc().startOf("day").valueOf()
            }
        },
        onCompleted(data) {
            setTypeIdsData(data.contents.map(content => ({ value: content.id, title: getText(content.title) })));
        }
    });

    if (!stepType) {
        return null;
    }

    if (loadingEvents || loadingChallenges || loadingContents) {
        return <LoaderComponent loading />;
    }

    return (
        <React.Fragment>
            {stepType === "typeform" && <MultiLangInfo />}
            <SelectField
                label={<FormattedMessage id={`flows.typeIds.${stepType}`} />}
                mode={getSelectMode(stepType)}
                name={[fieldName, "typeIds"]}
                rules={[requiredrule]}
            >
                {typeIdsData && typeIdsData.map(typeId => (
                    <Select.Option
                        key={typeId.value}
                        value={typeId.value}
                    >
                        {typeId.title}
                    </Select.Option>
                ))}
            </SelectField>
        </React.Fragment>
    );
};
