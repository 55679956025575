/* eslint-disable import/order */
/* eslint-disable global-require */

// activities
export const getUserActivities = require("./documents/activities/getUserActivities.graphql");
export const getProviderActivities = require("./documents/activities/getProviderActivities.graphql");
export const removeProviderActivity = require("./documents/activities/removeProviderActivity.graphql");

// ambassador
export const getAmbassadors = require("./documents/ambassador/getAmbassadors.graphql");
export const addAmbassador = require("./documents/ambassador/addAmbassador.graphql");
export const removeAmbassador = require("./documents/ambassador/removeAmbassador.graphql");
export const updateAmbassador = require("./documents/ambassador/updateAmbassador.graphql");

// ambassadorConfigs
export const getAmbassadorConfigs = require("./documents/ambassador/getAmbassadorConfigs.graphql");
export const getAmbassadorConfigCount = require("./documents/ambassador/getAmbassadorConfigCount.graphql");
export const addAmbassadorConfig = require("./documents/ambassador/addAmbassadorConfig.graphql");
export const updateAmbassadorConfig = require("./documents/ambassador/updateAmbassadorConfig.graphql");
export const removeAmbassadorConfig = require("./documents/ambassador/removeAmbassadorConfig.graphql");

// ambassadorTags
export const getAmbassadorTags = require("./documents/ambassador/getAmbassadorTags.graphql");
export const addAmbassadorTag = require("./documents/ambassador/addAmbassadorTag.graphql");
export const updateAmbassadorTag = require("./documents/ambassador/updateAmbassadorTag.graphql");
export const removeAmbassadorTag = require("./documents/ambassador/removeAmbassadorTag.graphql");

// content
export const getContents = require("./documents/content/getContents.graphql");
export const getContentCount = require("./documents/content/getContentCount.graphql");
export const getContentTitles = require("./documents/content/getContentTitles.graphql");
export const addContent = require("./documents/content/addContent.graphql");
export const updateContent = require("./documents/content/updateContent.graphql");
export const removeContent = require("./documents/content/removeContent.graphql");

// content tags
export const getContentTags = require("./documents/content/getContentTags.graphql");
export const addContentTag = require("./documents/content/addContentTag.graphql");
export const updateContentTag = require("./documents/content/updateContentTag.graphql");
export const removeContentTag = require("./documents/content/removeContentTag.graphql");

// comments
export const getComments = require("./documents/comments/getComments.graphql");
export const getCommentCount = require("./documents/comments/getCommentCount.graphql");
export const removeComment = require("./documents/comments/removeComment.graphql");
export const updateComment = require("./documents/comments/updateComment.graphql");

// translations
export const getTranslations = require("./documents/translations/getTranslations.graphql");
export const getTranslationCount = require("./documents/translations/getTranslationCount.graphql");
export const addTranslation = require("./documents/translations/addTranslation.graphql");
export const updateTranslation = require("./documents/translations/updateTranslation.graphql");
export const removeTranslation = require("./documents/translations/removeTranslation.graphql");

// challenge
export const getChallengesBasic = require("./documents/challenge/getChallengesBasic.graphql");
export const getChallengeCount = require("./documents/challenge/getChallengeCount.graphql");
export const getChallengesDetail = require("./documents/challenge/getChallengesDetail.graphql");
export const getChallengeTitles = require("./documents/challenge/getChallengeTitles.graphql");
export const addChallenge = require("./documents/challenge/addChallenge.graphql");
export const updateChallenge = require("./documents/challenge/updateChallenge.graphql");
export const removeChallenge = require("./documents/challenge/removeChallenge.graphql");
export const resyncChallenge = require("./documents/challenge/resyncChallenge.graphql");
export const getChallengeTeams = require("./documents/challenge/getChallengeTeams.graphql");
export const getChallengeTeamCount = require("./documents/challenge/getChallengeTeamCount.graphql");
export const removeChallengeTeam = require("./documents/challenge/removeChallengeTeam.graphql");
export const updateChallengeTeamCaptain = require("./documents/challenge/updateChallengeTeamCaptain.graphql");
export const removeFromChallengeTeam = require("./documents/challenge/removeFromChallengeTeam.graphql");
export const removeChallengeParticipation = require("./documents/challenge/removeChallengeParticipation.graphql");
export const banFromChallengeTeam = require("./documents/challenge/banFromChallengeTeam.graphql");
export const addChallengeTeam = require("./documents/challenge/addChallengeTeam.graphql");
export const updateChallengeTeam = require("./documents/challenge/updateChallengeTeam.graphql");
export const sendMilestoneEmail = require("./documents/challenge/sendMilestoneEmail.graphql");

// challenge participants
export const getChallengeParticipants = require("./documents/challenge/getChallengeParticipants.graphql");
export const getChallengeParticipantCount = require("./documents/challenge/getChallengeParticipantCount.graphql");
export const exportChallengeParticipation = require("./documents/challenge/exportChallengeParticipation.graphql");
export const exportChallengeParticipationFormConditionData = require("./documents/challenge/exportChallengeParticipationFormConditionData.graphql");

// challenge tags
export const getChallengeTags = require("./documents/challenge/getChallengeTags.graphql");
export const addChallengeTag = require("./documents/challenge/addChallengeTag.graphql");
export const updateChallengeTag = require("./documents/challenge/updateChallengeTag.graphql");
export const removeChallengeTag = require("./documents/challenge/removeChallengeTag.graphql");

// project
export const getApplications = require("./documents/getApplications.graphql");
export const getProjects = require("./documents/project/getProjects.graphql");
export const addRole = require("./documents/project/addRole.graphql");
export const updateRole = require("./documents/project/updateRole.graphql");
export const removeRole = require("./documents/project/removeRole.graphql");

// company
export const getCompanies = require("./documents/company/getCompanies.graphql");
export const addCompany = require("./documents/company/addCompany.graphql");
export const updateCompany = require("./documents/company/updateCompany.graphql");
export const removeCompany = require("./documents/company/removeCompany.graphql");

// credits
export const getCreditConfigs = require("./documents/credit/getCreditConfigs.graphql");
export const getCreditConfigCount = require("./documents/credit/getCreditConfigCount.graphql");
export const addCreditConfig = require("./documents/credit/addCreditConfig.graphql");
export const updateCreditConfig = require("./documents/credit/updateCreditConfig.graphql");

// dashboard
export const getDashboards = require("./documents/dashboard/getDashboards.graphql");
export const getDashboardsOverviewCount = require("./documents/dashboard/getDashboardsOverviewCount.graphql");
export const getDashboardsOverview = require("./documents/dashboard/getDashboardsOverview.graphql");
export const addDashboardConfiguration = require("./documents/dashboard/addDashboardConfiguration.graphql");
export const removeDashboardConfiguration = require("./documents/dashboard/removeDashboardConfiguration.graphql");
export const updateDashboardConfiguration = require("./documents/dashboard/updateDashboardConfiguration.graphql");

// event
export const getEventCount = require("./documents/event/getEventCount.graphql");
export const getEventTitles = require("./documents/event/getEventTitles.graphql");

export const getEventsOverview = require("./documents/event/getEventsOverview.graphql");
export const getEvents = require("./documents/event/getEvents.graphql");
export const getChronoRaceEvents = require("./documents/event/getChronoRaceEvents.graphql");
export const copyEvent = require("./documents/event/copyEvent.graphql");

export const addEvent = require("./documents/event/addEvent.graphql");
export const updateEvent = require("./documents/event/updateEvent.graphql");
export const removeEvent = require("./documents/event/removeEvent.graphql");

// event participation
export const getEventParticipants = require("./documents/event/getEventParticipants.graphql");
export const updateEventApproval = require("./documents/event/updateEventApproval.graphql");
export const removeEventParticipation = require("./documents/event/removeEventParticipation.graphql");
export const exportEventParticipation = require("./documents/event/exportEventParticipation.graphql");

// eventTags
export const getEventTags = require("./documents/event/getEventTags.graphql");
export const addEventTag = require("./documents/event/addEventTag.graphql");
export const updateEventTag = require("./documents/event/updateEventTag.graphql");
export const removeEventTag = require("./documents/event/removeEventTag.graphql");

// formTemplates
export const getFormTemplates = require("./documents/formTemplates/getFormTemplates.graphql");
export const getFormTemplatesOverview = require("./documents/formTemplates/getFormTemplatesOverview.graphql");
export const addFormTemplate = require("./documents/formTemplates/addFormTemplate.graphql");
export const updateFormTemplate = require("./documents/formTemplates/updateFormTemplate.graphql");
export const removeFormTemplate = require("./documents/formTemplates/removeFormTemplate.graphql");

export const getCheckupLocationAvailabilities = require("./documents/checkup/getCheckupLocationAvailabilities.graphql");
export const getCheckupLocationAvailabilityCount = require("./documents/checkup/getCheckupLocationAvailabilityCount.graphql");
export const removeCheckupLocationAvailability = require("./documents/checkup/removeCheckupLocationAvailability.graphql");
export const addCheckupLocationAvailability = require("./documents/checkup/addCheckupLocationAvailability.graphql");
export const updateCheckupLocationAvailability = require("./documents/checkup/updateCheckupLocationAvailability.graphql");
export const getCheckupLocations = require("./documents/checkup/getCheckupLocations.graphql");
export const getCheckupLocationCount = require("./documents/checkup/getCheckupLocationCount.graphql");
export const getCheckupParticipations = require("./documents/checkup/getCheckupParticipations.graphql");
export const getCheckupParticipationCount = require("./documents/checkup/getCheckupParticipationCount.graphql");

// event groups
export const getEventGroups = require("./documents/eventGroup/getEventGroups.graphql");
export const getEventGroupCount = require("./documents/eventGroup/getEventGroupCount.graphql");
export const addEventGroup = require("./documents/eventGroup/addEventGroup.graphql");
export const updateEventGroup = require("./documents/eventGroup/updateEventGroup.graphql");
export const removeEventGroup = require("./documents/eventGroup/removeEventGroup.graphql");

// journey
export const getJourneys = require("./documents/journey/getJourneys.graphql");
export const getJourneyCount = require("./documents/journey/getJourneyCount.graphql");
export const removeJourney = require("./documents/journey/removeJourney.graphql");
export const addJourney = require("./documents/journey/addJourney.graphql");
export const updateJourney = require("./documents/journey/updateJourney.graphql");

// journey tags
export const getJourneyTags = require("./documents/journey/getJourneyTags.graphql");
export const addJourneyTag = require("./documents/journey/addJourneyTag.graphql");
export const updateJourneyTag = require("./documents/journey/updateJourneyTag.graphql");
export const removeJourneyTag = require("./documents/journey/removeJourneyTag.graphql");

// gallery
export const addGallery = require("./documents/gallery/addGallery.graphql");
export const addGalleryImage = require("./documents/gallery/addGalleryImage.graphql");
export const getGalleries = require("./documents/gallery/getGalleries.graphql");
export const getGalleriesOverview = require("./documents/gallery/getGalleriesOverview.graphql");
export const getGalleriesOverviewCount = require("./documents/gallery/getGalleriesOverviewCount.graphql");
export const removeGallery = require("./documents/gallery/removeGallery.graphql");
export const removeGalleryImage = require("./documents/gallery/removeGalleryImage.graphql");
export const updateGallery = require("./documents/gallery/updateGallery.graphql");
export const updateGalleryImage = require("./documents/gallery/updateGalleryImage.graphql");

// gallery tags
export const addGalleryTag = require("./documents/gallery/addGalleryTag.graphql");
export const getGalleryTags = require("./documents/gallery/getGalleryTags.graphql");
export const updateGalleryTag = require("./documents/gallery/updateGalleryTag.graphql");
export const removeGalleryTag = require("./documents/gallery/removeGalleryTag.graphql");

// provider
export const unlinkProvider = require("./documents/provider/unlinkProvider.graphql");

// user
export const login = require("./documents/login.graphql");
export const logout = require("./documents/logout.graphql");
export const getUsers = require("./documents/user/getUsers.graphql");
export const getUsersLight = require("./documents/user/getUsersLight.graphql");
export const getUsersOverview = require("./documents/user/getUsersOverview.graphql");
export const getUsersDataToExport = require("./documents/user/getUsersDataToExport.graphql");
export const getUserCount = require("./documents/user/getUserCount.graphql");
export const getUserWhiteLists = require("./documents/user/getUserWhiteLists.graphql");
export const addUser = require("./documents/user/addUser.graphql");
export const updateUser = require("./documents/user/updateUser.graphql");
export const removeUser = require("./documents/user/removeUser.graphql");
export const removeUsers = require("./documents/user/removeUsers.graphql");
export const impersonateUser = require("./documents/user/impersonateUser.graphql");
export const addUserWhiteList = require("./documents/user/addUserWhiteList.graphql");
export const updateUserWhiteList = require("./documents/user/updateUserWhiteList.graphql");
export const removeUserWhiteList = require("./documents/user/removeUserWhiteList.graphql");
export const getUserCreditStatistics = require("./documents/user/getUserCreditStatistics.graphql");
export const createUserCreditTransaction = require("./documents/user/createUserCreditTransaction.graphql");
export const resyncProvider = require("./documents/user/resyncProvider.graphql");
export const validateEmail = require("./documents/user/validateEmail.graphql");
export const requestValidateEmail = require("./documents/user/requestValidateEmail.graphql");

// social
export const getSocialTags = require("./documents/social/getSocialTags.graphql");
export const getSocialFilterWords = require("./documents/social/getSocialFilterWords.graphql");
export const getInstagramFeed = require("./documents/social/getInstagramFeed.graphql");
export const getInstagramFeedCount = require("./documents/social/getInstagramFeedCount.graphql");
export const getTwitterFeed = require("./documents/social/getTwitterFeed.graphql");
export const getTwitterFeedCount = require("./documents/social/getTwitterFeedCount.graphql");
export const addSocialTag = require("./documents/social/addSocialTag.graphql");
export const updateSocialTag = require("./documents/social/updateSocialTag.graphql");
export const removeSocialTag = require("./documents/social/removeSocialTag.graphql");
export const addSocialFilterWord = require("./documents/social/addSocialFilterWord.graphql");
export const updateSocialFilterWord = require("./documents/social/updateSocialFilterWord.graphql");
export const removeSocialFilterWord = require("./documents/social/removeSocialFilterWord.graphql");
export const changeVisibleInstagramFeed = require("./documents/social/changeVisibleInstagramFeed.graphql");
export const changeVisibleTwitterFeed = require("./documents/social/changeVisibleTwitterFeed.graphql");

// voucher
export const addVoucher = require("./documents/voucher/addVoucher.graphql");
export const exportVoucher = require("./documents/voucher/exportVoucher.graphql");
export const getVoucherCount = require("./documents/voucher/getVoucherCount.graphql");
export const getVouchers = require("./documents/voucher/getVouchers.graphql");
export const removeVoucher = require("./documents/voucher/removeVoucher.graphql");
export const updateVoucher = require("./documents/voucher/updateVoucher.graphql");

// flows
export const getFlows = require("./documents/flows/getFlows.graphql");
export const getFlowsCount = require("./documents/flows/getFlowsCount.graphql");
export const addFlow = require("./documents/flows/addFlow.graphql");
export const removeFlow = require("./documents/flows/removeFlow.graphql");
export const updateFlow = require("./documents/flows/updateFlow.graphql");

// webshop
export const getArticlesOverview = require("./documents/articles/getArticlesOverview.graphql");
export const getArticles = require("./documents/articles/getArticles.graphql");
export const getArticleCount = require("./documents/articles/getArticleCount.graphql");
export const removeArticle = require("./documents/articles/removeArticle.graphql");
export const addArticle = require("./documents/articles/addArticle.graphql");
export const updateArticle = require("./documents/articles/updateArticle.graphql");
export const addArticleVouchers = require("./documents/articles/addArticleVouchers.graphql");
export const removeArticleVouchers = require("./documents/articles/removeArticleVouchers.graphql");

// orders
export const getOrders = require("./documents/orders/getOrders.graphql");
export const getOrdersCount = require("./documents/orders/getOrdersCount.graphql");

// session
export const getSession = require("./documents/session/getSession.graphql");

// projectConfig
export const getProjectConfigs = require("./documents/projectConfig/getProjectConfigs.graphql");
export const createProjectConfig = require("./documents/projectConfig/createProjectConfig.graphql");
export const updateProjectConfig = require("./documents/projectConfig/updateProjectConfig.graphql");
export const deleteProjectConfig = require("./documents/projectConfig/deleteProjectConfig.graphql");
